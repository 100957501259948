var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            attrs: { placeholder: "输入大币种名称进行搜索" },
            on: { pressEnter: _vm.search, change: _vm.valueNull },
            model: {
              value: _vm.searchInpt,
              callback: function($$v) {
                _vm.searchInpt = $$v
              },
              expression: "searchInpt"
            }
          }),
          _c("button", { staticClass: "search", on: { click: _vm.search } }, [
            _vm._v("搜索")
          ]),
          _c(
            "button",
            {
              staticClass: "add",
              staticStyle: { width: "90px" },
              attrs: { size: "large" },
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_vm._v("添加特征")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: "id",
              pagination: _vm.pagination
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(text) {
                  return _c("a", {}, [_vm._v(_vm._s(text))])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openFeatureModal(row)
                            }
                          }
                        },
                        [_vm._v("特征数据")]
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要删除此项么？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal" },
            [
              _c("p", [_vm._v("大币种")]),
              _c(
                "a-select",
                {
                  ref: "addse",
                  staticStyle: { width: "150px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    placeholder: "请选择大币种"
                  },
                  on: { search: _vm.searchBigData },
                  model: {
                    value: _vm.coinKindId,
                    callback: function($$v) {
                      _vm.coinKindId = $$v
                    },
                    expression: "coinKindId"
                  }
                },
                _vm._l(_vm.currency_big_list, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.coinKindName))]
                  )
                }),
                1
              ),
              _c("br"),
              _c("p", [_vm._v("特征名称")]),
              _c("a-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.featureTitle,
                  callback: function($$v) {
                    _vm.featureTitle = $$v
                  },
                  expression: "featureTitle"
                }
              }),
              _c("p", [_vm._v("筛选类型")]),
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.featureTypeChange },
                  model: {
                    value: _vm.featureType,
                    callback: function($$v) {
                      _vm.featureType = $$v
                    },
                    expression: "featureType"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v(" 小币种 ")]),
                  _c("a-radio", { attrs: { value: 2 } }, [_vm._v(" 版本 ")])
                ],
                1
              ),
              _c("p", [_vm._v("级别")]),
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.levelTypeChange },
                  model: {
                    value: _vm.level,
                    callback: function($$v) {
                      _vm.level = $$v
                    },
                    expression: "level"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v(" 一级 ")]),
                  _c("a-radio", { attrs: { value: 2 } }, [_vm._v(" 二级 ")])
                ],
                1
              ),
              _c("br"),
              _c("p", [_vm._v("排序")]),
              _c("a-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.sort,
                  callback: function($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消",
            width: 700
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideFeatureModal },
          model: {
            value: _vm.featureVisible,
            callback: function($$v) {
              _vm.featureVisible = $$v
            },
            expression: "featureVisible"
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("特征币种")]),
                    _c(
                      "a-select",
                      {
                        ref: "addse",
                        staticStyle: { width: "150px", margin: "5px" },
                        attrs: {
                          allowClear: "",
                          "filter-option": _vm.untils.filterOption,
                          showSearch: "",
                          placeholder: "请选择大币种"
                        },
                        on: {
                          search: _vm.searchFeatureBigData,
                          change: function($event) {
                            return _vm.changeBigSelect($event)
                          }
                        },
                        model: {
                          value: _vm.coinKindId,
                          callback: function($$v) {
                            _vm.coinKindId = $$v
                          },
                          expression: "coinKindId"
                        }
                      },
                      _vm._l(_vm.feature_big_list, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.coinKindId, attrs: { value: item.id } },
                          [_vm._v(_vm._s(item.coinKindName))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("特征选项")]),
                    _c(
                      "a-select",
                      {
                        ref: "addse",
                        staticStyle: { width: "150px", margin: "5px" },
                        attrs: {
                          allowClear: "",
                          "filter-option": _vm.untils.filterOption,
                          showSearch: "",
                          placeholder: "请选择特征"
                        },
                        model: {
                          value: _vm.featureId,
                          callback: function($$v) {
                            _vm.featureId = $$v
                          },
                          expression: "featureId"
                        }
                      },
                      _vm._l(_vm.coin_feature_list, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [_vm._v(_vm._s(item.featureTitle))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("br"),
              _vm._l(_vm.featureData, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("展示名称")]),
                        _c("a-input", {
                          staticStyle: { width: "100px" },
                          attrs: { placeholder: "" },
                          model: {
                            value: item.showName,
                            callback: function($$v) {
                              _vm.$set(item, "showName", $$v)
                            },
                            expression: "item.showName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("搜索名称")]),
                        _c("a-input", {
                          staticStyle: { width: "100px" },
                          attrs: { placeholder: "" },
                          model: {
                            value: item.searchName,
                            callback: function($$v) {
                              _vm.$set(item, "searchName", $$v)
                            },
                            expression: "item.searchName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("排序")]),
                        _c("a-input", {
                          staticStyle: { width: "50px" },
                          attrs: { placeholder: "" },
                          model: {
                            value: item.sort,
                            callback: function($$v) {
                              _vm.$set(item, "sort", $$v)
                            },
                            expression: "item.sort"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.level > 1
                      ? _c(
                          "div",
                          [
                            _c("a-checkbox-group", {
                              attrs: { options: _vm.coin_feature_first_list },
                              on: {
                                change: function($event) {
                                  return _vm.onChangeChecked($event, index)
                                }
                              },
                              model: {
                                value: _vm.featureData[index].ids,
                                callback: function($$v) {
                                  _vm.$set(_vm.featureData[index], "ids", $$v)
                                },
                                expression: "featureData[index].ids"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-button",
                      {
                        staticStyle: { width: "60px" },
                        on: { click: _vm.addFeature }
                      },
                      [_vm._v("添加")]
                    ),
                    index > 0
                      ? _c(
                          "a-button",
                          {
                            staticStyle: { width: "60px" },
                            on: {
                              click: function($event) {
                                return _vm.removeare(index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }